export default {
  common: {
    contentTypes: {
      tasks: "Aufgaben",
      appointments: "Termine",
      projects: "Projekte",
      customers: "Kunden",
    },
    form: {
      requiredText: "* Felder mit einem Stern sind zwingend aus zu füllen.",
      required: "Feld wird benötigt",
      requiredEmail: "E-mail wird benötigt",
      validEmail: "Ungültige Email",
      identicalEmail: "E-mail muss übereinstimmen",
      save: "Speichern",
    },
    list: {
      actions: "Actions",
      noResults: "Keine resultate",
    },
    tables: {
      filter: "Einträge filtern",
    },
    loading: {
      title: "Daten werden geladen, bitte warten",
    },
    dialog: {
      ok: "Ok",
      cancel: "Abbrechen",
    },
    labels: {
      add: "Hinzufügen",
      close: "Schliessen",
      save: "Speichern",
      filter: "Filtern",
      undefined: "Nicht definiert",
      years: "Jahre",
      months: "Monate",
      days: "Tage",
    },
  },
  login: {
    pageTitle: "Login",
    username: "Benutzername",
    form: {
      title: "Login Fenster",
      username: "Benutzer Name oder E-Mail",
      password: "Passwort",
      submit: "Einloggen",
      resetPwd: "Passwort vergessen?",
      resetemail: "Email von dem Account",
      resetInfo: "Es wird ihnen auf die Email oben einen Link geschickt mit welchen sie Ihr Passwort neu setzen können.",
      cancel: "Abrechen",
      submitreset: "Email anfordern",
    },
    status: {
      wrongCredentials: "Bitte überprüfen Sie Ihre Zugangsdaten.",
      serverError: "Login error, please try again later or contact support.",
    },
  },
  loginReset: {
    form: {
      title: "Passowrd zurücksetzen",
      pwd1: "Passwort",
      pwd2: "Passwort bestätigen",
    },
  },
  resetPwd: {
    pageTitle: "Password zurück setzen",
  },
  logout: {
    pageTitle: "Ausloggen",
    body: "Sie wurden ausgeloggt. Bitte loggen sie sich wieder ein um weiter arbeiten zu können.",
    backToLogin: "Zurück zum login",
  },
  search: {
    teaser: {
      title: "Suche",
      form: {
        query: "Suchen nach",
        submit: "Suche absetzen",
      },
      resultCount: "Resultat",
      noResults: "Keine Resultate für diese Suche",
    },
    labels: {
      freeTextSearch: "Freitextsuche",
    },
  },
  dashboard: {
    pageTitle: "Startseite",
    filter: {
      showAll: "Alles anzeigen",
      showAssigned: "Nur Elemente die mir zugeteilt sind zeigen",
    },
    projects: {
      filterPositions: "Projekt staties zum anzeigen",
    },
  },
  about: {
    pageTitle: "Über KLS",
  },
  tasks: {
    pageTitle: "Aufgaben",
    filters: {
      mine: "Meine Aufgaben",
      all: "Alle",
      archived: "Archivierte",
      created: "Aufgaben die ich erstellt hab",
    },
    range: {
      all: "Alle",
      overdue: "Abgelaufen",
      today: "Heute",
      tomorrow: "Morgen",
      next7days: "Nächsten 7 Tage",
    },
    list: {
      noDueDate: "Ohne Fälligkeitsdatum",
      noTasks: "Keine Aufgaben gefunden für dieses Projekt",
      task: {
        for: "Für",
        from: "von",
        on: "auf",
        forward: "weiter geben an",
      },
    },
    edit: {
      title: "Aufgabe beratbeiten/editieren",
      name: "Überschrift",
      body: "Inhalt",
      assigned: "Zuweisen",
      date: "Ablaufdatum",
      help: "Hilfe text. TBD.",
      customer: "Kunde",
    },
    delete: {
      title: "Notiz löschen",
      body: "Notiz wirklich löschen?",
    },
  },
  calendar: {
    pageTitle: "Kalender",
    filters: {
      mine: "Relevant für mich",
      all: "Alle",
      tasksOnly: "Nur tasks",
      appointmentsOnly: "Nur Termine",
    },
    cal: {
      days: {
        today: "Heute",
      },
      viewRange: {
        day: "Tag",
        week: "Woche",
        month: "Monat",
        vdays: "4 Tage",
      },
      showTasks: "Aufgaben anzeigen",
      toProject: "Zum Projekt",
      toTask: "Zur Aufgabe",
      close: "Schliessen",
    },
    edit: {
      title: "Callender eintrag ersellten/verwalten",
      help: "Hilfe zum erstellen von einem Kallender Eintrag",
      form: {
        title: "Betreff *",
        startDate: "Start Datum *",
        endDate: "End Datum",
        body: "Details",
        users: "Sichtbar für",
        customer: "Kunde",
      },
    },
    list: {
      title: "Betreff",
      dateTime: "Zeit",
      body: "Inahlt",
      noResults: "Keine Termine gefunden für diese Projekt",
    },
    delete: {
      title: "Termin löschen",
      body: "Termin wirklich löschen?",
    },
  },
  customers: {
    pageTitle: "Kunden",
    view: {
      notSelected: "Kein Kunde für dieses Projekt ausgewählt",
    },
    filters: {
      showCards: "Als Cards darstellen",
      showList: "Als Liste darstellen",
    },
    list: {
      firstname: "Vorname",
      name: "Name *",
      email: "E-mail",
      email2: "Weitere E-mail",
      phone: "Telefon",
      phone2: "Telefon 2",
      mobile: "Mobile",
      mobile2: "Mobile 2",
      seller: "Verkäufer",
      birthday: "Geburtstag",
      birthPlace: "Geburtsort",
      familyStatus: "Familienstand",
      addresses: "Addressen",
      firstContact: "Erstkontakt",
      appActivated: "App"
    },
    app: {
      info: "App Zugang",
      state: {
        true: "Aktiviert",
        false: "Deaktiviert",
      },
      action: {
        true: "Deaktivieren",
        false: "Aktivieren",
      },
    },
    pwd: {
      reset: "Password zurücksetzen"
    },
    addr: {
      street: "Strasse",
      houseNumber: "Nr",
      postCode: "Postleitzahl",
      place: "Ort",
      add: "Addresse hinzufügen",
    },
    edit: {
      title: "Kunden anlegen/verwalten",
      seller: "Verkäufer",
      help: "Hilfe text",
    },
    selector: {
      title: "Kunden auswählen",
      customer: "Kunde",
      help: "Irgend eine Hilfe",
    },
  },
  customer: {
    pageTitle: "Kunde",
    backToOverview: "Zurück zur Übersicht",
    data: {
      title: "Daten",
    },
    projects: {
      title: "Projekte",
    },
    appointments: {
      title: "Termine",
    },
    tasks: {
      title: "Aufgaben",
    },
    mails: {
      title: "E-Mails",
    },
    delete: {
      title: "Kunde löschen",
      body: "Kunde wirklich löschen???",
    },
  },
  project: {
    pageTitle: "Projekt",
    backToCustomer: "Kundenübersicht",
  },
  projects: {
    pageTitle: "Projekte",
    identifier: "ID",
    archivedMessage: "Dieses Projekt ist archiviert und wird deswegen in keinen Listen oder reports berücksichtigt.",
    filters: {
      mine: "Meine Projekte",
      all: "Alle Projekte",
      archived: "Archivierte Projekte",
    },
    labels: {
      info: "Info",
      actions: "Aktionen",
    },
    types: {
      title: "Position",
      position1: "Vorbereitungsphase",
      position2: "Darlehensantrag",
      position3: "Darlehenszusage",
      position4: "Auszahlungsphase",
      position5: "Bestandskunde",
      position6: "Antrag abgelehnt",
    },
    states: {
      title: "Status",
      open: "Aktiv",
      archived: "Archiviert",
    },
    infos: {
      title: "Projektname",
      rating: "Bewertung",
      volume: "Volume",
      projectType: "Porjekt Typ",
      seller: "Verkäufer",
      owner: "Kundenberater",
      customer: "Kunde",
      save: "Speichern",
      customerSearch: "Tippen zum suchen",
      runtime: "Projekt dauer",
      identifier: "ID",
    },
    actions: {
      back: "Zurück zur Übersicht",
      addNote: "Notiz hinzufügen",
      addTask: "Aufgabe hinzufügen",
      addBank: "Bank hinzufügen",
      addApointment: "Termin hinzufügen",
      sendMail: "Mail versenden",
      selectCustomer: "Kunde auswählen",
      add: "hinzufügen",
      delete: "Projekt löschen",
      archive: "Projekt archivieren",
      reactivate: "Projekt reaktivieren",
    },
    details: {
      title: "Einzelheiten",
    },
    files: {
      title: "Dateien",
    },
    edit: {
      title: "Projekt anlegen",
      name: "Projektname",
      help: "Hilfe zum erstellen von einem Projekt",
      identifier: "Kürzel",
    },
    delete: {
      title: "Projekt löschen",
      body: "Wollen sie das Projekt wirklich löschen?",
    },
  },
  mails: {
    pageTitle: "E-Mails",
    title: "E-Mails",
    list: {
      noResults: "Keine Mails gefunden welche an dieses Projekte angehängt sind",
      from: "Von",
      to: "An",
      sentDate: "Gesendet am",
      subject: "Betreff",
      body: "Inhalt",
    },
    edit: {
      title: "Mail verfassen",
      to: "Empfänger *",
      cc: "Zusetzlicher Empfänger",
      replyTo: "Antworten an",
      subject: "Betreff *",
      body: "Inahlt *",
      help: "Kontext hilfe zum Mail versand",
    },
  },
  users: {
    pageTitle: "Benutzer",
    list: {
      name: "Name *",
      email: "E-Mail *",
      state: "Status *",
      role: "Rolle *",
      lastLoginDate: "Letzter login am",
    },
    edit: {
      title: "Benutzer erstellen/verwalten",
      help: "Hilfe zum Benutzer editiern Fenster",
      password: {
        title: "Password ändern",
        currentPassword: "Aktuelles Passwort",
        password: "Neues Passwort",
        passwordValidation: "Passwort validieren",
        change: {
          submit: "Passwort speichern",
          successfull: "Passwort geändert",
          error: "Passowrt ändern fehlgeschlagen",
          backToLogin: "Zurück zum login",
        },
        showPassordInClear: "Passwort anzeigen",
      },
    },
  },
  userdelete: {
    pageTitle: "Benutzer verbindungen",
    info: {
      warning: "{ct} Verbindungen sind noch aktiv. Lösch oder verändere diese bis es keine mehr gibt, um den Benutzer löschen zu können",
      ok: "Benutzer hat keine relations und kann gelöscht werden.",
      projectDelete:
        "Aufgaben, Termine etc. die einem Projekt zugeordnet wurden, werden automatische mit gelöscht wenn das Projekt gelöscht wird." +
        " Aktualisieren sie die Liste nach dem löschen von einem Projekt indem Sie die Seite neu laden.",
    },
    back: "Zurück zur liste",
    delete: "Benutzer unwiederruflich löschen",
  },
  sellers: {
    pageTitle: "Verkäufer",
    list: {
      name: "Name *",
      organisation: "Organisation",
      email: "E-Mail",
      phone: "Telefon Nummer",
      phone2: "Weitere Telefon Nummer",
      mobile: "Natel",
    },
    edit: {
      title: "Verkäufer anlegen/verwalten",
      help: "Hilfe zum Verkäufer editiern Fenster",
      bankname: "Bankname",
      iban: "IBAN",
      bic: "Bic",
    },
  },
  projectTypes: {
    pageTitle: "Projekt Typen",
    list: {
      name: "Name *",
    },
    edit: {
      title: "Projekt Typ anlegen/verwalten",
      help: "Hilfe zum Projekt Typen editiern Fenster",
    },
  },
  projectPhases: {
    pageTitle: "Projekt Phasen",
    list: {
      name: "Name *",
    },
    edit: {
      title: "Projekt Phase anlegen/verwalten",
      help: "Hilfe zum Projekt Phasen editiern Fenster",
    },
  },
  banks: {
    pageTitle: "Banken",
    list: {
      name: "Name *",
      noResults: "Keine Investments gefunden für dieses Projekt",
    },
    edit: {
      title: "Bank anlegen/verwalten",
      help: "Hilfe zum Bank editiern Fenster",
      form: {
        should: "Soll",
        effective: "Effektiv",
        rate: "Monatliche Rate",
        commission: "Provision",
        sum: "Darlehenssumme",
        duration: "Zinsbindung",
        repayment: "Anf. Tilgung",
        specialrepayment: "Sondertilgung",
        invend: "Zinsbindungsende",
        bzfree: "BZ frei",
        help: "Irgend eine Hilfe",
      },
      comission: {
        percentage: "Prozente ausrechnen",
        howMany: "Wieviele",
      },
    },
    selector: {
      select: "Bank auswählen",
      title: "Bank auswählen",
    },
    filter: {
      banks: "Banken",
      loan: "Darlehn",
      duration: "Dauer",
      endsIn: "Endet in",
      remaining: "bleibend",
    },
  },
  investments: {
    pageTitle: "Darlehns",
    delete: {
      title: "Investment löschen",
      body: "Investment wirklich löschen?",
    },
  },
  notes: {
    pageTitle: "Notizen",
    list: {
      noResults: "Keine notizen welche an dieses Projekte angehängt sind konnte gefunden werden",
    },
    edit: {
      title: "Notiz erstelle/verwalten",
      body: "Inhalt der Notiz",
      help: "Hilfe text wenn Notizen angelegt werden",
    },
    delete: {
      title: "Notiz löschen",
      body: "Notiz wirklich löschen?",
    },
  },
  myProfile: {
    pageTitle: "Mein Profil",

    section: {
      user: "Benutzer",
      profile: "Profile einstellungen",
      imap: "Mailserver konfiguration",
    },

    switches: {
      showSearchOnHome: "Suche auf der Startseite anzeigen statt Modulübersicht",
      keepFiltersOverPages: "Filter einstellung wenn möglich von Seite zu Seite beibehalten",
      keepFiltersOpen: "Filter immer offen halten",
      showReleaseNotesOnLogin: "Release notes immer ignorieren",
      useDarkTheme: "Dunkles Aussehen verwenden",
    },
    labels: {
      language: "Sprache",
    },
    form: {
      saveProfile: "Profile speichern",
      saveSettings: "Einstellungen speichern",
    },
    imap: {
      server: "Mailserver",
      login: "Benutzername",
      pwd: "Passwort",
      statusLabel: "Imap status {status}"
    },
  },
  reports: {
    pageTitle: "Auswertungen",
    ppp: "Projekte pro Person",
    sumppp: "Summe der Summen der Projekte pro Person",
    cppp: "Anzahl Projekte pro position",
    cppt: "Anzahl Projekte pro type",
    ispb: "Investment Sum per Bank",
  },
  navigation: {
    admin: "Administration",
  },
  invests: {
    pageTitle: "Investments",
    filters: {
      relevant: "Relevant für mich",
      all: "Alles anzeigen",
      archived: "Archivierte anzeigen",
    },
    list: {
      bankname: "Bank",
      customer: "Kunde",
      sum: "Darlehensumme",
      rate: "Monatliche Rate",
      duration: "Zinsbindungsdauer",
      invend: "Bindungsende",
    },
  },
  status: {
    pageTitle: "Server Status",
    db: {
      title: "Datenbank",
    },
    mail: {
      title: "Mailserver",
    },
  },
  releaseNotes: {
    pageTitle: "Release notes",
    list: {
      features: "Funktionalitäten",
      bugfixes: "Fehler behebungen",
      others: "Weiteres",
    },
    popup: {
      title: "Neue Version ausgerollt",
      read: "Release notes lesen",
      continue: "Weiter zur App",
      gotIt: "Habs gesehen, danke",
    },
  },
  configs: {
    pageTitle: "Sys-config",
    switches: {
      showEditMode: "JSON Config direkt editieren",
    },
    editMode: {
      textAreaLabel: "JSON Konfiguration",
    },
  },
};
