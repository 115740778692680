export default {
  state: {
    loading: false,
  },
  getters: {
    isLoading: (state) => state.loading,
  },
  mutations: {
    setLoader(state, data) {
      if (data) {
        state.loading = data;
      } else {
        state.loading = null;
      }
    },
  },
  actions: {
    loading({ commit }, st) {
      commit("setLoader", st);
    },
  },
};
