<template>
  <router-view />
</template>

<script>
import { loadThemeFromConfig, setDark } from "@/services/theme.js";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {},

  data: () => ({}),

  computed: {
    ...mapGetters(["profile"]),
  },

  created() {
    loadThemeFromConfig(this.$vuetify);
    if (this.profile && this.profile.useDarkTheme) {
      setDark(true);
    } else {
      setDark(false);
    }
  },
};
</script>

<style lang="scss">
.v-application {
  font-family: "Poppins", sans-serif;
}

.v-card__title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif !important;
}

.timeLine {
  padding-bottom: 1rem;

  .timeElement {
    margin-right: 1rem;

    .timeContent {
      //border-radius: 5px;
      border-left: 3px solid #aaaaaa;
      padding: 0 1rem;
      margin: 1rem 0;
    }
  }

  .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 48px);
  }
  .v-timeline-item__divider {
    min-width: 48px;
  }
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: 24px !important;
}

.pointer {
  cursor: pointer;
}

.mhover {
  &:hover {
    text-decoration: underline;
    font-weight: bold;
    background-color: silver;
    -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);

    i {
      text-decoration: none;
    }
  }
}

.dark {
  .mhover:hover {
    color: black;
  }
}
</style>
