import axios from "axios";

import { clearLogoutTimer } from "../../helpers/timeout.js";

export default {
  state: {
    user: null,
    msg: [],
  },
  getters: {
    isLoggedIn: (state) => state.user !== null,
    isAdmin: (state) => state.user && state.user.role === "admin",
    userId: (state) => state.user && state.user.id,
    userName: (state) => state.user && state.user.name,
    profile: (state) => state.user && state.user.profile,
    role: (state) => state.user && state.user.role,
    user: (state) => state.user,
  },
  mutations: {
    setUser(state, data) {
      if (data) {
        state.user = data;
      } else {
        state.user = null;
      }
    },
  },
  actions: {
    login({ commit }, { username, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/users/login", {
            username: username,
            password: password,
          })
          .then((res) => {
            commit("setUser", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setUser({ commit }, data) {
      return axios.post("/api/users/profile", data).then((res) => {
        commit("setUser", res.data);
      });
    },

    logout({ commit }) {
      return axios.post("/api/users/logout").then(() => {
        commit("setUser", null);
        clearLogoutTimer();
      });
    },
  },
};
