export default {
  state: {
    settings: {},
  },
  getters: {
    settings: (state) => state.settings,
  },
  mutations: {
    setSetting(state, data) {
      if (data) {
        state.settings = {...state.settings, ...data };
      }
    },
  },
  actions: {
    setSetting({ commit }, data) {
      commit("setSetting", data);
    },
  },
};
