import axios from "axios";
import store from "../store";
import router from "../router";
import moment from "moment";

var logoutTimer = null;

function calcTimeout() {
  if (store && store.getters && store.getters.settings && store.getters.settings.timeoutDate) {
    var mom = moment(store.getters.settings.timeoutDate - new Date().valueOf());
    var seconds = mom.seconds() + mom.minutes() * 60;

    if (seconds % 60 == 0) {
        console.log("Timer", seconds);
    }

    if (seconds <= 0) {
      clearInterval(logoutTimer);
      router.push("/logout");
    }

    this.remaining = mom.format("mm:ss");
  }
}

function startLogoutTimer() {
  logoutTimer = setInterval(calcTimeout, 1000);
  //console.log("start Logout timer");
}

function clearLogoutTimer() {
  clearInterval(logoutTimer);
}

function checkSession() {
    axios
    .get("/api/users/profile")
    .then(() => {
      startLogoutTimer();
    })
    .catch(() => {
    });
}

export { startLogoutTimer, clearLogoutTimer, checkSession };
