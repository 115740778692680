import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
    meta: { prominentHeader: false },
  },
  {
    path: "/tasks/:id?",
    name: "tasks",
    component: () => import("../views/tasks.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/calendars",
    name: "calendar",
    component: () => import("../views/appointments.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/calendars/:id",
    name: "calendardetails",
    component: () => import("../views/appointments.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("../views/customers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: () => import("../views/customer.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("../views/projects.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects/:id",
    name: "project",
    component: () => import("../views/project.vue"),
    meta: { requiresAuth: true, prominentHeader: false },
  },
  {
    path: "/invests",
    name: "investments",
    component: () => import("../views/invests.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/login/reset",
    name: "reset Pwd",
    component: () => import("../views/loginreset.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logout.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/reports.vue"),
  },
  {
    path: "/releasenotes",
    name: "releaseNotes",
    component: () => import("../views/release_notes.vue"),
  },
  {
    path: "/myprofile",
    name: "myProfile",
    component: () => import("../views/myprofile.vue"),
    meta: { requiresAuth: true, color: "orange" },
  },
  {
    path: "/admin/status",
    name: "status",
    component: () => import("../views/admin/status.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/colors",
    name: "colors",
    component: () => import("../views/admin/colors.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/configs",
    name: "configs",
    component: () => import("../views/admin/config.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/users",
    name: "users",
    component: () => import("../views/admin/users.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
  {
    path: "/admin/users/:id",
    name: "userdelete",
    component: () => import("../views/admin/userdelete.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
  {
    path: "/admin/sellers",
    name: "sellers",
    component: () => import("../views/admin/sellers.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
  {
    path: "/admin/projecttypes",
    name: "projectTypes",
    component: () => import("../views/admin/projecttypes.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
  {
    path: "/admin/projectphases",
    name: "projecthases",
    component: () => import("../views/admin/projectphases.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
  {
    path: "/admin/banks",
    name: "banks",
    component: () => import("../views/admin/banks.vue"),
    meta: { requiresAuth: true, requireAdmin: true, color: "red" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta && record.meta.requiresAuth)) {
    if (!Store.state.Auth.user) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
  next();
});

export default router;
