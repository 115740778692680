import axios from "axios";

export default {
  state: {
    config: {},
  },
  getters: {
    config: (state) => state.config,
  },
  mutations: {
    setConfig(state, data) {
      if (data) {
        state.config = data;
      } else {
        state.config = null;
      }
    },
  },
  actions: {
    loadConfig({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/admin/configs")
          .then((res) => {
            let cfg = {};

            res.data.forEach(function(o) {
              o.key.split('.').reduce(function(r, e, i, arr) {
                return r[e] = (r[e] || (arr[i + 1] ? {} : o.val))
              }, cfg)
            })

            commit("setConfig", cfg);
            resolve(cfg);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
