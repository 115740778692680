import store from "../store";
import vuetify from "@/plugins/vuetify";

export function getColor(key) {
  if (store && store.getters && store.getters.config) {
    let config = store.getters.config;

    if (config && config.theme) {
      if (vuetify && vuetify.framework.theme && vuetify.framework.theme.dark) {
        if (config.theme.dark && config.theme.dark[key]) {
          return config.theme.dark[key];
        }
      } else {
        if (config.theme.light && config.theme.light[key]) {
          return config.theme.light[key];
        }
      }
    }
  }
  return "";
}

export function loadThemeFromConfig(vueti) {
  if (store && store.getters && store.getters.config) {
    loadTheme(store.getters.config, vueti);
  }
}

export function loadTheme(config) {
  if (config && config.theme) {
    const dark = config.theme.dark;
    const light = config.theme.light;
    Object.keys(dark).forEach((i) => {
      vuetify.framework.theme.themes.dark[i] = dark[i];
    });
    Object.keys(light).forEach((i) => {
      vuetify.framework.theme.themes.light[i] = light[i];
    });
    vuetify.framework.theme.themes.name = "test";

    let theme = vuetify.framework.theme.isDark ? "dark" : "light";

    const value = vuetify.framework.theme.themes[theme];
    vuetify.framework.theme.themes[theme] = {};
    vuetify.framework.theme.themes[theme] = value;
  }
}

export function isDark() {
  return vuetify.framework.theme.isDark;
}

export function setDark(isDark) {
  vuetify.framework.theme.isDark = !!isDark;

  let theme = vuetify.framework.theme.isDark ? "dark" : "light";

  const value = vuetify.framework.theme.themes[theme];
  vuetify.framework.theme.themes[theme] = {};
  vuetify.framework.theme.themes[theme] = value;
}
