import Vue from "vue";
import axios from "axios";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
import CKEditor from "@ckeditor/ckeditor5-vue";
import moment from "moment";

import "./plugins/vuetify-money.js";
import { checkSession } from "./helpers/timeout.js";

Vue.use(CKEditor);
Vue.use(DatetimePicker);

checkSession();

store.dispatch("loadConfig").then((u) => {
  console.log("Config loaded", u);
});

axios.interceptors.response.use(
  (response) => {
    store
      .dispatch("setSetting", {
        timeoutDate: moment()
          .add(20, "m")
          .valueOf(),
      })
      .catch((e) => {
        console.log("ERROR setting timeout store:", e);
      });
    return response;
  },
  (error) => {
    if (!error || !error.response || error.response.status === 401) {
      store.dispatch("logout");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
