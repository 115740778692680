import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'EUR', 
  locale: 'de',
  "distraction-free": true }
}
Vue.use(VueCurrencyInput, pluginOptions)
export default VueCurrencyInput;