import Vue from "vue";
import Vuetify from "vuetify/lib";

//import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#37474F",
        secondary: "",
        accent: "#8E24AA",
        bg:"#E1E1E1",
        headerbg: "#C0CA33",
        navbg: "#616161"
      },
    }
  },
});
