import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store";

import deCH from "./de-CH";
import en from "./en";

Vue.use(VueI18n);

let options = {
  locale: "de",
  messages: {
    de: deCH,
    en: en
  },
  silentTranslationWarn: true // comment out this line to see warnings for missing keys
}

if (store && store.getters && store.getters.user && store.getters.user.lang) {
  options.locale = store.getters.user.lang;
}

const i18n = new VueI18n(options);

export default i18n;
