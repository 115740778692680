export default {
  state: {
    snack: {},
  },
  getters: {
    snack: (state) => state.snack,
  },
  mutations: {
    showMessage (state, payload) {
      state.snack = payload;
    },
  },
  actions: {
    showSnackMessage ({ commit }, payload) {
      commit("showMessage", payload);
    },
  },
};
